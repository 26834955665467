import React from "react";
import styles from "./LangSelector.module.css";

const ThemeSelector = ({ searchParams, selectedTheme }) => {
    return (
        <div>
            <div className={styles.flexCenter}>
                <select className={styles.select} value={selectedTheme} onChange={(e) => {
                    searchParams.set("theme", e.target.value);
                    window.location.search = searchParams.toString();
                }}>
                    <option value="light">Light</option>
                    <option value="blue" >Blue</option>
                    <option value="gold" >Gold</option>
                    <option value="medina">Medina</option>
                    <option value="blue2">Blue2</option>
                    <option value="betconstruct">Betconstruct</option>
                    <option value="kung" >Kung</option>
                    <option value="dbet">Dbet</option>
                    <option value="orange">Orange</option>
                    <option value="wood">Wood</option>
                    <option value="lion">Lion</option>
                    <option value="video">Video</option>
                    <option value="mrvegas">MrVegas</option>
                    <option value="green">Green</option>
                </select>
            </div>
        </div>
    );
}

export default ThemeSelector;